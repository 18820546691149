import React from 'react';
import './App.css';
import beardays from './beardays.json'
import Countdown from 'react-countdown';

class BearDay {
  date: Date;
  title: string;
  picture: string;
  description: string;
  fact: string;
  factSource: string;
  altText: string;

  constructor(date: Date, title: string, picture: string, description: string, fact: string, factSource: string, altText: string) {
    this.date = this.calculateDateYear(date);
    this.title = title;
    this.picture = picture;
    this.description = description;
    this.fact = fact;
    this.factSource = factSource
    this.altText = altText
  }

  public toString = () : string => {
    return `${this.title}`;

  }

  private calculateDateYear = (date: Date): Date => {
    let today = new Date();
    let thisMonth = today.getMonth();
    let thisDay = today.getDay();
    let thisYear = today.getFullYear();
    let bearMonth = date.getMonth();

    if(bearMonth === thisMonth){
      // Then we need to check the days to check if it's happened
      let bearDateDay = date.getDay();
      if(bearDateDay > thisDay){
        // It hasn't happened yet so it's this year
        date.setFullYear(thisYear);
      }
      else{
        date.setFullYear(thisYear + 1);
      }
    }
    else if(bearMonth > thisMonth){
      // It hasn't happened yet
      date.setFullYear(thisYear);
    }
    else{
      // It's already been
      date.setFullYear(thisYear + 1);
    }
    
    return date;
  }
}


function isItBearday(currentDate: Date): BearDay | string {
  let jsonBearDay = beardays.bearDayList.find(i => new Date(i.date).getUTCDate() === currentDate.getUTCDate() && new Date(i.date).getUTCMonth() === currentDate.getUTCMonth())
  if(jsonBearDay){
    // return h i m b
    return new BearDay(
      new Date(jsonBearDay.date),
      jsonBearDay.title,
      jsonBearDay.picture,
      jsonBearDay.description,
      jsonBearDay.fact,
      jsonBearDay.factSource,
      jsonBearDay.altText
    )

  }
  else{
    return "It is not bear day :("
    // return "no :("
  }
}

function findNextBearDay(currentDate: Date): BearDay {
  for(let jsonBearDay of beardays.bearDayList){
    console.log(jsonBearDay)
    if(new Date(jsonBearDay.date).getUTCMonth() > currentDate.getUTCMonth())
    {
     
     // found the next bear day!
      return new BearDay(
      new Date(jsonBearDay.date),
      jsonBearDay.title,
      jsonBearDay.picture,
      jsonBearDay.description,
      jsonBearDay.fact,
      jsonBearDay.factSource,
      jsonBearDay.altText
      )
      
    } 
    if(new Date(jsonBearDay.date).getUTCDate() > currentDate.getUTCDate() && new Date(jsonBearDay.date).getUTCMonth() === currentDate.getUTCMonth() ){
      // found the next bear day!
      return new BearDay(
        new Date(jsonBearDay.date),
        jsonBearDay.title,
        jsonBearDay.picture,
        jsonBearDay.description,
        jsonBearDay.fact,
        jsonBearDay.factSource,
        jsonBearDay.altText
      )
    }
    
  }
  // Must be next year
  let newBearDate = new Date(beardays.bearDayList[0].date)
  newBearDate.setFullYear(newBearDate.getUTCFullYear() + 1)
  return new BearDay(
    newBearDate,
    beardays.bearDayList[0].title,
    beardays.bearDayList[0].picture,
    beardays.bearDayList[0].description,
    beardays.bearDayList[0].fact,
    beardays.bearDayList[0].factSource,
    beardays.bearDayList[0].altText
  )
}

const BearDayView: React.FC<{bearDay: BearDay}> = ({bearDay}) => {
  return <>
    <h2 className='text'>{bearDay.date.toDateString()} is {bearDay.title}!</h2>
    <img alt={bearDay.altText} className="bear-img" src={"../resources/images/"+bearDay.picture+".jpg"}/>
    <p className='text'>{bearDay.description}</p>
    <p className='text'>Fun fact: {bearDay.fact}</p>
    <p className='text'>Fact source: <a className="link" href={bearDay.factSource}>{bearDay.factSource}</a></p>
  </>
}

const renderer = ({ days, hours, minutes, seconds, completed }: {days:number, hours:number, minutes: number, seconds:number, completed: boolean}) => {
  if (completed) {
    // Render a completed state
    return <></>
  } else {
    // Render a countdown
    return <span>{days} days, {hours} hours, {minutes} minutes, {seconds} seconds</span>;
  }
};

const BearDayCountdown: React.FC<{bearDay: BearDay}> = ({bearDay}) => {
  return <>
  <p className="countdown">The next bear day is {bearDay.title} on {bearDay.date.toDateString()}</p>
  <p className='countdown'><Countdown date={bearDay.date}  renderer={renderer} className='countdown'/></p>
  </>
}



function App() {
  let isBearDay = isItBearday(new Date())
  return (
    <div className="App">
      <div className="App-header">
        <title>Is it bear day?</title>
        <h1>Is it bear day?</h1>
        <h2>{isBearDay instanceof BearDay ?  <i>YES!</i> : "no :(" } </h2>
        <p className='bear-day'>
          {isBearDay instanceof BearDay ?  <BearDayView bearDay={isBearDay}/> : "It is not a bear day :(" }     
        </p>
      </div>
      <BearDayCountdown bearDay={findNextBearDay(new Date())}></BearDayCountdown>
    </div>
  );
}

export default App;
